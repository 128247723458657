let endPoints = {
    login: '/login',
    logout:'/logout',
    resetPassword: '/password/reset/email',
    verifyOTP: '/verify/otp',
    resendOTP: '/email/verification-notification/resend',
    updatePassword: '/update/password',
    register: 'register',
    activityLogs: '/members/activity/logs',
    members: '/members',
    memberSoftDeleted:"/members/soft/deleted",
    memberRestoreDeleted:"/members/restore/deleted/",
    media: '/media',
    memberWithID:'/members/',
    pendingMember:'/members/all/pending',
    vehicles: '/vehicles',
    vehiclesWithID: '/vehicles/',
    vehicleType: '/vehicle-types',
    makeModel: '/make-models',
    clubs: "/clubs",
    showClubs: "/clubs",
    storeClubs: "/clubs/store",
    clubDetail:"/clubs/detail/",
    clubSoftDeleted:"/clubs/soft/deleted",
    clubRestoreDeleted:"/clubs/restore/deleted",
    getClubItems:"/clubs/get/items",
    washAppointments:"/wash-appointments",
    cancelAppointment:"wash-appointments/cancel",
    checkIn: '/wash-appointments/checkin/complete/',
    staff: "/employees",
    staffWithID: "employees/",
    softDeletedStaff: "employees/soft/deleted",
    restoreDeletedStaff: "employees/restore/deleted",
    feedback: '/feedback',
    subscriptionPlans: "subscription-plans",
    cancelSubscriptionPlan: '/subscription-plans/cancel',
    subscriptionFeature: "subscription-features",
    jobs: '/jobs',
    dashboardJobsCount: '/admin/dashboard/count/jobs',
    dashboardEmployeesCount: '/admin/dashboard/count/employees',
    dashboardUsersGraph: '/admin/dashboard/users/graph',
    dashboardWashChart: '/admin/dashboard/cars/washed',
    createFcmToken:  '/fcm',
    services: '/services',
    allNotifications: '/notifications/all-notifications',
    readNotifications: '/notifications/read-single',
    readAllNotifications: '/notifications/read-notifications',
    countUnreadNotifications: '/notifications/unread-notifications-count',

    //questions
    getQuestions: '/questions',
    questions: '/questions',
    showQuestion: 'questions',
    updateQuestion: '/questions',
    deleteQuestions: 'questions',

    //platforms
    platforms: '/platforms',  // create
    getPlatforms: '/platforms', // listing
    showPlatforms: 'platforms', // single show
    updatePlatforms: '/platforms', // update
    deletePlatforms: 'platforms', 

    //club schedule
    clubSchedule: '/club-schedules',  // create
    getClubSchedule: '/club-schedules',  // list
    updateClubSchedule: '/club-schedules',  // update
    showClubSchedule: '/club-schedules',  // show single detail

    //feedback
    showFeedback: '/feedback',

    //export csv 
    exportCSV: '/export-csv/files',

    // punch card uses history
    punchCardUsesHistory: '/punch-card/uses/history',
    deleteWashHistory: '/assign-staff',

    //Email notifications 
    emailNotifications: '/email-notifications',
 
}
export default endPoints;