import store from '@/state/store';

export default [{
  path: '/login',
  name: 'login',
  component: () => import('../views/pages/account/login'),
  meta: {
    title: "Login",
    beforeResolve(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({
          name: 'home'
        });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
},
{
  path: '/register',
  name: 'register',
  component: () => import('../views/pages/account/register'),
  meta: {
    title: "Register",
    beforeResolve(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({
          name: 'home'
        });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
},
{
  path: '/reset-password',
  name: 'Reset-password',
  component: () => import('../views/pages/account/reset-password'),
  meta: {
    title: "Reset Password",
    beforeResolve(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({
          name: 'home'
        });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
},
{
  path: '/code-authentication',
  name: 'Code-authentication',
  component: () => import('../views/pages/account/CodeAuthentication.vue'),
  meta: {
    title: "Code-authentication",
    beforeResolve(routeTo, routeFrom, next) {
      // If the user is already logged in
      if (store.getters['auth/loggedIn']) {
        // Redirect to the home page instead
        next({
          name: 'home'
        });
      } else {
        // Continue to the login page
        next();
      }
    },
  },
},
{
  path: '/new-password',
  name: 'New-Password',
  component: () => import('../views/pages/account/NewPassword.vue'),
  meta: { title: "new password", authRequired: true },
},
{
  path: '/logout',
  name: 'logout',
  meta: {
    title: "Logout",
    authRequired: true,
    beforeResolve(routeTo, routeFrom, next) {
      if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
        store.dispatch('auth/logOut');
      } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
        store.dispatch('authfack/logout');
      }
      const authRequiredOnPreviousRoute = routeFrom.matched.some(
        (route) => route.push('/login')
      );
      // Navigate back to previous page, or home as a fallback
      next(authRequiredOnPreviousRoute ? {
        name: 'home'
      } : {
        ...routeFrom
      });
    },
  },
},
{
  path: '/',
  name: 'home',
  meta: { title: "Dashboard", authRequired: true },
  component: () => import('../views/pages/dashboard/index')
},

{
  path: '/calendar',
  name: 'calendar',
  meta: { title: "Calendar", authRequired: true },
  component: () => import('../views/pages/calendar/index')
},
{
  path: '/chat',
  name: 'chat',
  meta: { title: "Chat", authRequired: true },
  component: () => import('../views/pages/chat/index')
},
{
  path: '/ecommerce/products',
  name: 'Products',
  meta: { title: "Products", authRequired: true },
  component: () => import('../views/pages/ecommerce/products')
},
{
  path: '/ecommerce/product-detail/:id',
  name: 'Product Detail',
  meta: { title: "Products Details", authRequired: true },
  component: () => import('../views/pages/ecommerce/product-detail')
},
{
  path: '/ecommerce/orders',
  name: 'Orders',
  meta: { title: "Orders", authRequired: true },
  component: () => import('../views/pages/ecommerce/orders')
},
{
  path: '/ecommerce/customers',
  name: 'Customers',
  meta: { title: "Customers", authRequired: true },
  component: () => import('../views/pages/ecommerce/customers')
},
{
  path: '/ecommerce/cart',
  name: 'Cart',
  meta: { title: "Cart", authRequired: true },
  component: () => import('../views/pages/ecommerce/cart')
},
{
  path: '/ecommerce/checkout',
  name: 'Checkout',
  meta: { title: "Checkout", authRequired: true },
  component: () => import('../views/pages/ecommerce/checkout')
},
{
  path: '/ecommerce/shops',
  name: 'Shops',
  meta: { title: "Shops", authRequired: true },
  component: () => import('../views/pages/ecommerce/shops')
},
{
  path: '/ecommerce/add-product',
  name: 'Add Product',
  meta: { title: "Add Product", authRequired: true },
  component: () => import('../views/pages/ecommerce/add-product')
},
{
  path: '/email/inbox',
  name: 'Inbox',
  meta: { title: "Inbox", authRequired: true },
  component: () => import('../views/pages/email/inbox')
},
{
  path: '/email/reademail/:id',
  name: 'Read Email',
  meta: { title: "Read Email", authRequired: true },
  component: () => import('../views/pages/email/reademail')
},
{
  path: '/invoices/detail',
  name: 'Invoice Detail',
  meta: { title: "Invoice Detail", authRequired: true },
  component: () => import('../views/pages/invoices/detail')
},
{
  path: '/invoices/list',
  name: 'Invoice List',
  meta: { title: "Invoice List", authRequired: true },
  component: () => import('../views/pages/invoices/list')
},
//Activity Logs
{
  path: '/activity/logs',
  name: 'Activity Logs',
  meta: { title: "Activity Logs", authRequired: true },
  component: () => import('../views/pages/activityLog/activityListing.vue')
},
{
  path: '/users/grid',
  name: 'User Grid',
  meta: { title: "User Grid", authRequired: true },
  component: () => import('../views/pages/users/grid')
},
{
  path: '/users/list',
  name: 'User List',
  meta: { title: "User List", authRequired: true },
  component: () => import('../views/pages/users/list')
},
{
  path: '/users/list/:status',
  name: 'Pending Users',
  meta: { title: "Pending Users", authRequired: true },
  component: () => import('../views/pages/users/list')
},
{
  path: '/users/add',
  name: 'Add User',
  meta: { title: "Add User", authRequired: true },
  component: () => import('../views/pages/users/add')
},
{
  path: '/non/member/add',
  name: 'Add Non-member',
  meta: { title: "Add User", authRequired: true },
  component: () => import('../views/pages/users/add-non-member.vue')
},
{
  path: '/user/edit/:id',
  name: 'EditUser',
  meta: { title: "Edit User", authRequired: true },
  component: () => import('../views/pages/users/add')
},
{
  path: '/user/edit-profile/:id',
  name: 'EditUserInfo',
  meta: { title: "Edit User Information", authRequired: true },
  component: () => import('../views/pages/users/edit')
},
{
  path: '/user/detail/:id',
  name: 'UserDetail',
  meta: { title: "User Detail", authRequired: true },
  component: () => import('../views/pages/users/user-detail.vue')
},
{
  path: '/users/profile',
  name: 'Profile',
  meta: { title: "Profile", authRequired: true },
  component: () => import('../views/pages/users/profile')
},

{
  path: '/users/soft/deleted',
  name: 'users-soft-deleted',
  meta: { title: "Deleted Members", authRequired: true },
  component: () => import('../views/pages/users/soft-deleted-list')
},
// club menu 
  {
    path: '/clubs/list',
    name: 'clubList',
    meta: { title: "Clubs List", authRequired: true },
    component: () => import('../views/pages/clubs/clubList')
  },
  {
    path: '/clubs/add',
    name: 'club-add',
    meta: { title: "Add club", authRequired: true },
    component: () => import('../views/pages/clubs/addClub')
  },
  {
    path: '/clubs/edit/:id',
    name: 'club-edit',
    meta: { title: "Edit club", authRequired: true },
    component: () => import('../views/pages/clubs/addClub')
  },
  {
    path: '/club/sync',
    name: 'club-sync',
    meta: { title: "Sync club", authRequired: true },
    component: () => import('../views/pages/clubs/syncClub')
  },
  {
    path: '/clubs/details/:id',
    name: 'club-details',
    meta: { title: "Club Details", authRequired: true },
    component: () => import('../views/pages/clubs/clubDetails')
  },
  {
    path: '/clubs/soft/deleted',
    name: 'club-soft-deleted',
    meta: { title: "Deleted Clubs", authRequired: true },
    component: () => import('../views/pages/clubs/deletedClubList')
  },
// Staff menu 
  {
    path: '/staff/list',
    name: 'staff-list',
    meta: { title: "Staff List", authRequired: true },
    component: () => import('../views/pages/staff/staffList')
  },
  {
    path: '/staff/details/:id',
    name: 'staffDetails',
    meta: { title: "Staff Details", authRequired: true },
    component: () => import('../views/pages/staff/staffDetails')
  },
  {
    path: '/staff/add',
    name: 'staff-add',
    meta: { title: "Staff Add", authRequired: true },
    component: () => import('../views/pages/staff/addStaff')
  },
  {
    path: '/staff/edit/:id',
    name: 'staffEdit',
    meta: { title: "Staff Details", authRequired: true },
    component: () => import('../views/pages/staff/editStaff')
  },
  {
    path: '/staff/soft/deleted',
    name: 'softDeletedStaff',
    meta: { title: "Soft Deleted Staff", authRequired: true },
    component: () => import('../views/pages/staff/softDeletedList')
  },
  // Subscriptions Routes
  {
    path: '/subscriptions/list',
    name: 'subscriptionsList',
    meta: { title: "Subscriptions", authRequired: true },
    component: () => import('../views/pages/subscriptions/subscriptionsList')
  },
  {
    path: '/add/subscriptions',
    name: 'add-subscription',
    meta: { title: "Subscriptions", authRequired: true },
    component: () => import('../views/pages/subscriptions/addSubscription.vue')
  },
  {
    path: '/edit/subscription/:id',
    name: 'edit-subscription',
    meta: { title: "Edit Subscriptions", authRequired: true },
    component: () => import('../views/pages/subscriptions/addSubscription.vue')
  },
  {
    path: '/subscription/detail/:id',
    name: 'subscriptionDetail',
    meta: { title: "Subscription Detail", authRequired: true },
    component: () => import('../views/pages/subscriptions/subscriptionDetail.vue')
  },
  {
    path: '/features/list',
    name: 'allFeatures',
    meta: { title: "All Feature", authRequired: true },
    component: () => import('../views/pages/subscriptions/features/allFeatures.vue')
  },
  {
    path: '/add/subscription/features/',
    name: 'addSubscriptionFeature',
    meta: { title: "Add Feature", authRequired: true },
    component: () => import('../views/pages/subscriptions/features/addFeatures.vue')
  },
  {
    path: '/edit/subscription/features/:id',
    name: 'editSubscriptionFeature',
    meta: { title: "Edit Feature", authRequired: true },
    component: () => import('../views/pages/subscriptions/features/addFeatures.vue')
  },
  {
    path: '/feature/detail/:id',
    name: 'featureDetail',
    meta: { title: "Feature Detail", authRequired: true },
    component: () => import('../views/pages/subscriptions/features/featureDetail.vue')
  },
// vehicles menu 
  {
    path: '/vehicles/list',
    name: 'vehicle-List',
    meta: { title: "Vehicles List", authRequired: true },
    component: () => import('../views/pages/vehicles/vehicleList.vue')
  },
  {
    path: '/vehicles/add',
    name: 'Add-vehicle',
    meta: { title: "Add Vehicle", authRequired: true },
    component: () => import('../views/pages/vehicles/addVehicle.vue')
  },
  {
    path: '/vehicle/edit/:id',
    name: 'EditVehicle',
    meta: { title: "Edit Vehicle", authRequired: true },
    component: () => import('../views/pages/vehicles/addVehicle.vue')
  },
  {
    path: '/vehicles/details/:id',
    name: 'VehicleDetail',
    meta: { title: "Vehicle Details", authRequired: true },
    component: () => import('../views/pages/vehicles/vehicleDetail.vue')
  },
  // washing menu 
  {
    path: '/washing/list',
    name: 'washing-list',
    meta: { title: "Washing List", authRequired: true },
    component: () => import('../views/pages/washings/washingList')
  },
  {
    path: '/washing/add',
    name: 'washingAdd',
    meta: { title: "Add Washing", authRequired: true },
    component: () => import('../views/pages/washings/addWashing')
  },
  {
    path: '/washing/edit/:id',
    name: 'washingEdit',
    meta: { title: "Edit Washing", authRequired: true },
    component: () => import('../views/pages/washings/addWashing')
  },
  {
    path: '/washings/details/:id',
    name: 'washDetails',
    meta: { title: "Wash Details", authRequired: true },
    component: () => import('../views/pages/washings/washingDetails')
  },
  //Jobs Module
  {
    path: '/jobs/list',
    name: 'jobs-list',
    meta: { title: "Jobs List", authRequired: true },
    component: () => import('../views/pages/jobs/jobsList.vue')
  },
  {
    path: '/job/add',
    name: 'add-job',
    meta: { title: "Add Job", authRequired: true },
    component: () => import('../views/pages/jobs/addJob.vue')
  },
  {
    path: '/job/edit/:id',
    name: 'edit-job',
    meta: { title: "Edit Job", authRequired: true },
    component: () => import('../views/pages/jobs/addJob.vue')
  },
  {
    path: '/job/details/:id',
    name: 'job-details',
    meta: { title: "Job Details", authRequired: true },
    component: () => import('../views/pages/jobs/jobDetails.vue')
  },

// services module
{
  path: '/services/list',
  name: 'services-list',
  meta: { title: "Services List", authRequired: true },
  component: () => import('../views/pages/services/servicesList.vue')
},
{
  path: '/add/services',
  name: 'add-services',
  meta: { title: "Add Services", authRequired: true },
  component: () => import('../views/pages/services/addServices.vue')
},
{
  path: '/edit/service/:id',
  name: 'edit-service',
  meta: { title: "Edit Services", authRequired: true },
  component: () => import('../views/pages/services/addServices.vue')
},
{
  path: '/services/detail/:id',
  name: 'services-detail',
  meta: { title: "Service Detail", authRequired: true },
  component: () => import('../views/pages/services/servicesDetail.vue')
},

// Feedback module
{
  path: '/feedback/list',
  name: 'feedback-list',
  meta: { title: "Feedback List", authRequired: true },
  component: () => import('../views/pages/feedback/feedbackList.vue')
},
{
  path: '/feedback/details/:id',
  name: 'feedbackDetails',
  meta: { title: "Feedback Details", authRequired: true },
  component: () => import('../views/pages/feedback/feedbackDetails')
},
// Email Notification
{
  path: '/notifications/list',
  name: 'all-notification',
  meta: { title: "All Notification", authRequired: true },
  component: () => import('../views/pages/EmailNotification/NotificationsList.vue')
},
{
  path: '/notifications/detail/:id',
  name: 'detail-notification',
  meta: { title: "Notification Detail", authRequired: true },
  component: () => import('../views/pages/EmailNotification/NotificationDetail.vue')
},
{
  path: '/add/notification',
  name: 'add-notification',
  meta: { title: "Add Notification", authRequired: true },
  component: () => import('../views/pages/EmailNotification/AddNotification.vue')
},
//Settings
{
  path: '/update/profile',
  name: 'Update Profile',
  meta: { title: "Update Profile", authRequired: true },
  component: () => import('../views/pages/setting/updateProfile')
},
{
  path: '/utility/starter',
  name: 'Starter-page',
  meta: { title: "Starter page", authRequired: true },
  component: () => import('../views/pages/utility/starter')
},
{
  path: '/utility/maintenance',
  name: 'maintenance',
  meta: { title: "Maintenance", authRequired: true },
  component: () => import('../views/pages/utility/maintenance')
},
{
  path: '/utility/timeline',
  name: 'timeline',
  meta: { title: "Timeline", authRequired: true },
  component: () => import('../views/pages/utility/timeline')
},
{
  path: '/utility/faqs',
  name: 'faqs',
  meta: { title: "FAQs", authRequired: true },
  component: () => import('../views/pages/utility/faqs')
},
{
  path: '/utility/pricing',
  name: 'pricing',
  meta: { title: "Pricing", authRequired: true },
  component: () => import('../views/pages/utility/pricing')
},
{
  path: '/utility/404',
  name: 'error-404',
  meta: { title: "Error 404", authRequired: true },
  component: () => import('../views/pages/utility/404')
},
{
  path: '/utility/500',
  name: 'error-500',
  meta: { title: "Error 500", authRequired: true },
  component: () => import('../views/pages/utility/500')
},
{
  path: '/form/elements',
  name: 'elements',
  meta: { title: "Elements", authRequired: true },
  component: () => import('../views/pages/form/elements')
},
{
  path: '/form/validation',
  name: 'validation',
  meta: { title: "Validation", authRequired: true },
  component: () => import('../views/pages/form/validation')
},
{
  path: '/form/advanced',
  name: 'form-advanced',
  meta: { title: "Form Advanced", authRequired: true },
  component: () => import('../views/pages/form/advanced')
},
{
  path: '/form/editor',
  name: 'editor',
  meta: { title: "Editors", authRequired: true },
  component: () => import('../views/pages/form/editor')
},
{
  path: '/form/upload',
  name: 'upload',
  meta: { title: "File Upload", authRequired: true },
  component: () => import('../views/pages/form/upload')
},
{
  path: '/form/repeater',
  name: 'repeater',
  meta: { title: "Repeater", authRequired: true },
  component: () => import('../views/pages/form/repeater')
},
{
  path: '/form/wizard',
  name: 'wizard',
  meta: { title: "Wizard", authRequired: true },
  component: () => import('../views/pages/form/wizard')
},
{
  path: '/form/mask',
  name: 'mask',
  meta: { title: "Mask", authRequired: true },
  component: () => import('../views/pages/form/mask')
},
{
  path: '/tables/basic',
  name: 'basic-table',
  meta: { title: "Basic Table", authRequired: true },
  component: () => import('../views/pages/tables/basic')
},
{
  path: '/tables/advanced',
  name: 'advanced',
  meta: { title: "Advanced Table", authRequired: true },
  component: () => import('../views/pages/tables/advanced')
},
{
  path: '/charts/apex',
  name: 'apex',
  meta: { title: "Apex", authRequired: true },
  component: () => import('../views/pages/charts/apex/index')
},
{
  path: '/charts/chartjs',
  name: 'chartjs',
  meta: { title: "Chartjs", authRequired: true },
  component: () => import('../views/pages/charts/chartjs/index')
},
{
  path: '/charts/echart',
  name: 'echart',
  meta: { title: "EChart", authRequired: true },
  component: () => import('../views/pages/charts/echart/index')
},
{
  path: '/icons/unicons',
  name: 'unicons',
  meta: { title: "Unicons", authRequired: true },
  component: () => import('../views/pages/icons/unicons')
},
{
  path: '/icons/boxicons',
  name: 'boxicons',
  meta: { title: "Boxicons", authRequired: true },
  component: () => import('../views/pages/icons/boxicons')
},
{
  path: '/icons/materialdesign',
  name: 'materialdesign',
  meta: { title: "Material Design", authRequired: true },
  component: () => import('../views/pages/icons/materialdesign')
},
{
  path: '/icons/dripicons',
  name: 'dripicons',
  meta: { title: "Dripicons", authRequired: true },
  component: () => import('../views/pages/icons/dripicons')
},
{
  path: '/icons/fontawesome',
  name: 'fontawesome',
  meta: { title: "Font Awesome", authRequired: true },
  component: () => import('../views/pages/icons/fontawesome')
},
{
  path: '/maps/google',
  name: 'google',
  meta: { title: "Google Maps", authRequired: true },
  component: () => import('../views/pages/maps/google')
},
{
  path: '/maps/leaflet',
  name: 'leaflet',
  meta: { title: "Leaflet Maps", authRequired: true },
  component: () => import('../views/pages/maps/leaflet')
},
{
  path: '/ui/alerts',
  name: 'alerts',
  meta: { title: "Alerts", authRequired: true },
  component: () => import('../views/pages/ui/alerts')
},
{
  path: '/ui/buttons',
  name: 'buttons',
  meta: { title: "Buttons", authRequired: true },
  component: () => import('../views/pages/ui/buttons')
},
{
  path: '/ui/cards',
  name: 'cards',
  meta: { title: "Cards", authRequired: true },
  component: () => import('../views/pages/ui/cards')
},
{
  path: '/ui/carousel',
  name: 'carousel',
  meta: { title: "Carousel", authRequired: true },
  component: () => import('../views/pages/ui/carousel')
},
{
  path: '/ui/dropdown',
  name: 'dropdown',
  meta: { title: "Dropdown", authRequired: true },
  component: () => import('../views/pages/ui/dropdown')
},
{
  path: '/ui/grid',
  name: 'grid',
  meta: { title: "Grid", authRequired: true },
  component: () => import('../views/pages/ui/grid')
},
{
  path: '/ui/images',
  name: 'images',
  meta: { title: "Images", authRequired: true },
  component: () => import('../views/pages/ui/images')
},
{
  path: '/ui/lightbox',
  name: 'lightbox',
  meta: { title: "Lightbox", authRequired: true },
  component: () => import('../views/pages/ui/lightbox')
},
{
  path: '/ui/modals',
  name: 'modals',
  meta: { title: "Modals", authRequired: true },
  component: () => import('../views/pages/ui/modals')
},
{
  path: '/ui/rangeslider',
  name: 'rangeslider',
  meta: { title: "Range Slider", authRequired: true },
  component: () => import('../views/pages/ui/rangeslider')
},
{
  path: '/ui/progressbar',
  name: 'progressbar',
  meta: { title: "Progress Bars", authRequired: true },
  component: () => import('../views/pages/ui/progressbar')
},
{
  path: '/ui/placeholder',
  name: 'placeholder',
  meta: { title: "Placeholder", authRequired: true },
  component: () => import('../views/pages/ui/placeholder')
},
{
  path: '/ui/sweet-alert',
  name: 'sweet-alert',
  meta: { title: "Sweet-Alert", authRequired: true },
  component: () => import('../views/pages/ui/sweet-alert')
},
{
  path: '/ui/tabs-accordions',
  name: 'tabs-accordions',
  meta: { title: "Tabs & Accordions", authRequired: true },
  component: () => import('../views/pages/ui/tabs-accordions')
},
{
  path: '/ui/typography',
  name: 'typography',
  meta: { title: "Typography", authRequired: true },
  component: () => import('../views/pages/ui/typography')
},

{
  path: '/ui/video',
  name: 'video',
  meta: { title: "Video", authRequired: true },
  component: () => import('../views/pages/ui/video')
},
{
  path: '/ui/general',
  name: 'general',
  meta: { title: "General", authRequired: true },
  component: () => import('../views/pages/ui/general')
},
{
  path: '/ui/colors',
  name: 'colors',
  meta: { title: "Colors", authRequired: true },
  component: () => import('../views/pages/ui/colors')
},
{
  path: '/ui/rating',
  name: 'rating',
  meta: { title: "Rating", authRequired: true },
  component: () => import('../views/pages/ui/rating')
},
{
  path: '/auth/login-1',
  name: 'login-1',
  meta: { title: "Login", authRequired: true },
  component: () => import('../views/pages/auth/login-1')
},
{
  path: '/auth/register-1',
  name: 'register-1',
  meta: { title: "Register", authRequired: true },
  component: () => import('../views/pages/auth/register-1')
},
{
  path: '/auth/lock-screen',
  name: 'lock-screen',
  meta: { title: "Lock Screen", authRequired: true },
  component: () => import('../views/pages/auth/lock-screen')
},
{
  path: '/auth/recoverpwd',
  name: 'recoverpwd',
  meta: { title: "Recover Password", authRequired: true },
  component: () => import('../views/pages/auth/recoverpwd')
},
  {
    path: '/comingsoon',
    name: 'comingsoon',
    meta: { title: "Comingsoon", authRequired: true },
    component: () => import('../views/pages/comingSoon')
  },

  {
    path: '/questions',
    name: 'questions',
    meta: { title: "Questions", authRequired: true },
    component: () => import('../views/pages/questions/questionsList')
  },

  //platforms
  {
    path: '/platforms/listing',
    name: 'platforms-listing',
    meta: { title: "Platform Listing", authRequired: true },
    component: () => import('../views/pages/platforms/platformListing')
  },
  {
    path: '/platforms',
    name: 'add-platform',
    meta: { title: "Add", authRequired: true },
    component: () => import('../views/pages/platforms/addPlatform')
  },
  {
    path: '/platform/edit/:id',
    name: 'edit-platform',
    meta: { title: "Edit Platform", authRequired: true },
    component: () => import('../views/pages/platforms/addPlatform.vue')
  },
  {
    path: '/platforms/detail/:id',
    name: 'platform-detail',
    meta: { title: "platform details", authRequired: true },
    component: () => import('../views/pages/platforms/platformDetail.vue')
  },

  //club shedule
  {
    path: '/add/schedule',
    name: 'add-schedule',
    meta: { title: "add schedule", authRequired: true },
    component: () => import('../views/pages/clubSchedule/addSchedule.vue')
  },
  {
    path: '/schedule/list',
    name: 'list-schedule',
    meta: { title: "schedule list", authRequired: true },
    component: () => import('../views/pages/clubSchedule/scheduleList.vue')
  },
  {
    path: '/edit/schedule/:id',
    name: 'edit-schedule',
    meta: { title: "edit schedule", authRequired: true },
    component: () => import('../views/pages/clubSchedule/addSchedule.vue')
  },
  {
    path: '/show/schedule/:id',
    name: 'show-schedule',
    meta: { title: "show schedule", authRequired: true },
    component: () => import('../views/pages/clubSchedule/scheduleDetail.vue')
  },

  // wash history using punch card
  {
    path: '/wash/history',
    name: 'wash-history',
    meta: { title: "Wash History List", authRequired: true },
    component: () => import('../views/pages/washings/punchcard/punchCardUsageHistory.vue')
  },
  {
    path: '/wax/history',
    name: 'wax-history',
    meta: { title: "Wax History List", authRequired: true },
    component: () => import('../views/pages/washings/punchcard/waxCardUsageHistory.vue')
  },
];